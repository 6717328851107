<template>
  <el-main>
    <el-form class="select_store" label-width="85px">
      <el-form-item label="统计时间：">
        <el-date-picker v-model="date" value-format="yyyy-MM" default-value type="month" placeholder="选择日期" @change="getTime"></el-date-picker>
      </el-form-item>
      <el-form-item label="选择店铺：">
        <el-select v-model="store_id" filterable placeholder="请选择" @change="selectStore">
          <el-option v-for="item in storeList" :key="item.id" :label="item.store_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="day-data">
      <!-- 整体看板 -->
      <div class="whole-kanban">
        <div class="header">
          <span></span>
          <span>整体看板</span>
        </div>
        <overallKanban v-if="thirtyDataKanban.is_show" @getThirtyDataKanban="getThirtyDataKanban" ref="whole_kanban" :type="1" domId="whole-kanban" :content="thirtyDataKanban"></overallKanban>
      </div>
      <!-- 网店转化 -->
      <div class="transaction-rate">
        <div class="header">
          <span></span>
          <span>网店交易转化</span>
        </div>
        <div class="transaction-rate-con">
          <div class="item">
            <div class="visit-num">
              <div class="visit-num-item">
                <p>访问人数</p>
                <p>{{ storeRate.today_visit_num }}</p>
                <p>较前一日</p>
                <p>{{ storeRate.today_visit_num > 0 && storeRate.yestoday_visit_num > 0 ? ((storeRate.today_visit_num / storeRate.yestoday_visit_num) * 100).toFixed(2) : '0' }}%</p>
              </div>
            </div>
            <div class="type">
              <div class="name">
                <el-image :src="require('@/assets/image/visit-icon1.png')"></el-image>
                <p>访客</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="visit-num">
              <div class="visit-num-item">
                <p>下单人数</p>
                <p>{{ storeRate.create_order_num_today }}</p>
                <p>较前一日</p>
                <p>
                  {{
                    storeRate.create_order_num_today > 0 && storeRate.create_order_num_yesterday > 0
                      ? ((storeRate.create_order_num_today / storeRate.create_order_num_yesterday) * 100).toFixed(2)
                      : '0'
                  }}%
                </p>
              </div>
              <div class="visit-num-item">
                <p>下单数量</p>
                <p>{{ storeRate.create_order_log_today_num }}</p>
                <p>较前一日</p>
                <p>
                  {{
                    storeRate.create_order_log_today_num > 0 && storeRate.create_order_log_yesterday_num > 0
                      ? ((storeRate.create_order_log_today_num / storeRate.create_order_log_yesterday_num) * 100).toFixed(2)
                      : '0'
                  }}%
                </p>
              </div>
              <div class="visit-num-item">
                <p>下单金额（元）</p>
                <p>{{ storeRate.today_create_order_num }}</p>
                <p>较前一日</p>
                <p>
                  {{
                    storeRate.today_create_order_num > 0 && storeRate.yesterday_create_order_num > 0
                      ? ((storeRate.today_create_order_num / storeRate.yesterday_create_order_num) * 100).toFixed(2)
                      : '0'
                  }}%
                </p>
              </div>
            </div>
            <div class="type">
              <div class="name">
                <el-image :src="require('@/assets/image/visit-icon2.png')"></el-image>
                <p>下单</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="visit-num">
              <div class="visit-num-item">
                <p>付款人数</p>
                <p>{{ storeRate.pay_order_num_today }}</p>
                <p>较前一日</p>
                <p>{{ storeRate.pay_order_num_today > 0 && storeRate.pay_order_num_yesterday > 0 ? ((storeRate.pay_order_num_today / storeRate.pay_order_num_yesterday) * 100).toFixed(2) : '0' }}%</p>
              </div>
              <div class="visit-num-item">
                <p>付款单数</p>
                <p>{{ storeRate.pay_success_log_today_order_num }}</p>
                <p>较前一日</p>
                <p>
                  {{
                    storeRate.pay_success_log_today_order_num > 0 && storeRate.pay_success_log_yesterday_order_num > 0
                      ? ((storeRate.pay_success_log_today_order_num / storeRate.pay_success_log_yesterday_order_num) * 100).toFixed(2)
                      : '0'
                  }}%
                </p>
              </div>
              <div class="visit-num-item">
                <p>付款金额（元）</p>
                <p>{{ storeRate.today_pay_success_num }}</p>
                <p>较前一日</p>
                <p>
                  {{
                    storeRate.today_pay_success_num > 0 && storeRate.yesterday_pay_success_num > 0 ? ((storeRate.today_pay_success_num / storeRate.yesterday_pay_success_num) * 100).toFixed(2) : '0'
                  }}%
                </p>
              </div>
              <div class="visit-num-item">
                <p>客单价（元）</p>
                <p>{{ storeRate.today_uni_price }}</p>
                <p>较前一日</p>
                <p>{{ storeRate.today_uni_price > 0 && storeRate.yesterday_uni_price > 0 ? ((storeRate.today_uni_price / storeRate.yesterday_uni_price) * 100).toFixed(2) : '0' }}%</p>
              </div>
            </div>
            <div class="type">
              <div class="name">
                <el-image :src="require('@/assets/image/visit-icon3.png')"></el-image>
                <p>支付</p>
              </div>
            </div>
          </div>
          <div class="jiantou">
            <el-image :src="require('@/assets/image/data-jt.png')"></el-image>
            <div class="visit-order1 visit-order">
              <p>访问-下单转化率</p>
              <p>{{ storeRate.visit_order_rate }}%</p>
            </div>
            <div class="visit-order2 visit-order">
              <p>下单-支付转化率</p>
              <p>{{ storeRate.order_pay_rate }}%</p>
            </div>
            <div class="visit-order3 visit-order">
              <p>访问-支付转化率</p>
              <p>{{ storeRate.visit_pay_rate }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="store-rank">
        <div class="header">
          <span></span>
          <span>店铺排行榜</span>
        </div>
        <div id="store-rank"></div>
      </div>
      <div class="refund-kanban">
        <div class="header">
          <span></span>
          <span>退款看板</span>
        </div>
        <overallKanban ref="refund_kanban" v-if="refundBlock.is_show" @getThirtyDataKanban="getRefundBlock" domId="refund-kanban" :type="1" :content="refundBlock"></overallKanban>
        <div class="refund-goods">
          <tab :list="tab_options"></tab>
          <el-table :data="refundGoodsList" style="width: 100%">
            <el-table-column prop="rank" label="排名" width="120" align="center"></el-table-column>
            <el-table-column prop="goods_name" label="商品"></el-table-column>
            <el-table-column prop="refund_amount" label="成功退款金额（元）" sortable></el-table-column>
            <el-table-column prop="order_amount" label="支付金额（元）" sortable></el-table-column>
            <el-table-column prop="sum" label="成功退款笔数" sortable></el-table-column>
            <el-table-column prop="refund_rate" label="退款率" sortable>
              <template v-slot="{ row }">
                {{ row.refund_rate + '%' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import overallKanban from './components/overallKanban';
import { getDateformat } from '@/util/getDate';
import { analysis, survey } from './data.js';
import * as echarts from 'echarts';
import tab from './components/tab';
export default {
  components: {
    overallKanban,
    tab,
  },
  data() {
    let month = getDateformat(Date.parse(new Date()) / 1000).slice(0, 7);
    return {
      tab_options: ['退款商品'],
      date: new Date(),
      date_optons: [
        {
          label: '日',
          val: 1,
        },
        {
          label: '周',
          val: 2,
        },
        {
          label: '月',
          val: 3,
        },
        {
          label: '季度',
          val: 4,
        },
      ],
      refundGoodsList: [],
      tabIndex: 1,
      storeList: [],
      store_id: -1,
      storeRate: {},
      refundBlock: {
        is_show: !1,
        data: {},
        date: [],
        num_list: [
          {
            name: '成功退款金额（元）',
            plate_type: 'refund_success_num',
            key: 'refund_success_num',
            checked: !0,
          },
          {
            name: '成功退款笔数',
            plate_type: 'refund_success_num_count',
            key: 'refund_success_num_count',
            checked: !1,
          },
          {
            name: '退款率',
            plate_type: 'refund_success_redio',
            key: 'refund_success_num_redio',
            checked: !1,
          },
          {
            name: '申请退款平均处理时长（小时）',
            plate_type: 'refund_dateline_time_success',
            key: 'refund_dateline_time_success_num',
            checked: !1,
            width: '260px',
          },
        ],
      },
      thirtyData: {},
      thirtyDataKanban: {
        is_show: !1,
        data: survey.options4,
        date: [],
        num_list: [
          {
            name: '支付金额',
            plate_type: 'payment_amount',
            key: 'today_pay_success_num_price', //匹配整体看板界面下面echarts表格，接口返回的数据key的赋值
            key_val: 'order_total_price', //匹配整体看板界面上面选项，接口返回的数据key的赋值
            checked: !0,
          },
          {
            name: '支付订单数',
            plate_type: 'payment_nums',
            key: 'today_pay_success_num',
            key_val: 'payment_order_num',
            checked: !1,
          },
          {
            name: '访问量',
            plate_type: 'visitor_num',
            key: 'visit_num',
            key_val: 'visitor_num',
            checked: !1,
          },
          {
            name: '商品浏览数',
            plate_type: 'visitor_goods_num',
            key: 'goods_visit_num',
            key_val: 'visitor_goods_num',
            checked: !1,
          },
          {
            name: '支付人数',
            plate_type: 'number_payers',
            key: 'payment_people_num',
            key_val: 'payment_people_num',
            checked: !1,
          },
          {
            name: '客单价',
            plate_type: 'unit_price',
            key: 'unit_price_pre',
            key_val: 'unit_price',
            checked: !1,
          },
          {
            name: '成功退款金额',
            plate_type: 'refund_success',
            key: 'refund_goods_list_success',
            key_val: 'refund_success_num',
            checked: !1,
          },
          {
            name: '新增会员数',
            plate_type: 'new_open',
            key: 'new_open_num',
            key_val: 'new_user_open',
            checked: !1,
          },
          {
            name: '储值金额',
            plate_type: 'store_amount',
            key: 'card_recharge_success',
            key_val: 'payment_num',
            checked: !1,
          },
          {
            name: '储值卡销售金额',
            plate_type: 'stored_value_cards',
            key: 'card_user_open',
            key_val: 'payment_total_price',
            checked: !1,
          },
        ],
      },
      //整体看板请求参数
      thirtyDataKanbanForm: {
        store_id: -1,
        tab_type: 'operation_windows',
        date: month,
        plate_type: '', //payment_amount—支付金额 payment_nums 支付订单数 visitor_num 访客数 visitor_goods_num 商品浏览数 number_payers 支付人数 unit_price 客单价 refund_success 成功退款金额 new_open 新增会员数 store_amount 储值金额 stored_value_cards 储值卡销售金额
      },
      //退款看板请求参数
      refundBlockForm: {
        plate_type: 'refund_success_num',
        date: month,
      },
    };
  },
  async created() {
    this.getStoreRankingList();
    this.getRefundGoods();
    this.getStore();
    this.getStore_transaction();
    await this.getDataOverView();
    this.getThirtyDataKanban(0);
  },
  mounted() {
    this.getRefundBlock(0);
  },
  methods: {
    selectStore() {
      let thirtyDataKanban = this.thirtyDataKanban;
      this.thirtyDataKanbanForm.store_id = this.store_id;
      thirtyDataKanban.num_list.map(item => {
        item.num_arr = '';
        item.checked = !1;
      });
      this.getThirtyDataKanban(0);
      this.getRefundGoods();
    },
    getTime(val) {
      if (val) {
        let thirtyDataKanban = this.thirtyDataKanban;
        this.thirtyDataKanbanForm.date = val;
        thirtyDataKanban.num_list.map(item => {
          item.num_arr = '';
          item.checked = !1;
        });
        this.getThirtyDataKanban(0);
      }
    },
    getStoreRankingList() {
      this.$axios.post(this.$api.data.storeRankingList).then(res => {
        if (res.code == 0) {
          let data = analysis.options;
          let info = res.result || {};
          let series = [];
          let legend = [];
          for (let i in info.list) {
            info.list[i].rank = i + 1;
            series.push({
              value: info.list[i].percent,
              name: info.list[i].name,
            });
            legend.push(info.list[i].name);
          }
          if (info.amount_sum) {
            data.graphic.style.text = ['全店支付金额(元)', info.amount_sum].join('\n\n');
          } else {
            data.graphic.style.text = ['暂无数据'];
          }
          if (!series.length) {
            series.push({
              value: 0,
              itemStyle: {
                color: '#F7F8FA',
                emphasis: { color: '#5470c6' },
              },
            });
            data.series[0].label = {};
          }
          data.series[0].data = series;
          data.legend.data = legend;
          let chartDom = document.getElementById('store-rank');
          let myChart = echarts.init(chartDom);
          console.log(data);
          myChart.setOption(data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取整体看板数据
    getDataOverView() {
      return new Promise(resolve => {
        this.$axios.post(this.$api.data.dataOverView).then(res => {
          if (res.code == 0) {
            let info = res.result;
            let num_list = this.thirtyDataKanban.num_list;
            //赋值整体看板上面的数据
            for (let i in num_list) {
              for (let y in info) {
                if (num_list[i].key_val == y) {
                  num_list[i].num_today = info[y].today_before;
                  num_list[i].num_yesterday = info[y].yestoday_conversion;
                  num_list[i].num_last_week = info[y].last_week_conversion;
                }
              }
            }
            this.thirtyDataKanban.is_show = !0; //从这里开始加载组件显示
            this.thirtyDataKanban.num_list = num_list;
            resolve();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    getThirtyDataKanban(index) {
      let thirtyDataKanbanForm = this.thirtyDataKanbanForm;
      let currentInfo = this.thirtyDataKanban.num_list[index];
      thirtyDataKanbanForm.plate_type = currentInfo.plate_type;
      this.$axios.post(this.$api.data.getThirtyData, thirtyDataKanbanForm).then(res => {
        if (res.code == 0) {
          let info = res.result.date_month;
          currentInfo.num_arr = info[currentInfo.key];
          currentInfo.checked = !0;
          this.thirtyDataKanban.date = info.date;
          this.$refs.whole_kanban.initData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取退款看板数据
    getRefundBlock(index) {
      let num_list = this.refundBlock.num_list;
      this.refundBlockForm.plate_type = num_list[index].plate_type;
      this.$axios.post(this.$api.data.getRefundBlock, this.refundBlockForm).then(res => {
        if (res.code == 0) {
          let info = res.result;
          this.refundBlock.data = analysis.options1;
          this.refundBlock.date = info.data.date;
          for (let i in num_list) {
            for (let y in info.data_count) {
              if (num_list[i].key == y) {
                num_list[i].num_today = info.data_count[y].today_before;
                num_list[i].num_yesterday = Math.floor(info.data_count[y].yestoday_conversion * 100) / 100;
                num_list[i].num_last_week = Math.floor(info.data_count[y].last_week_conversion * 100) / 100;
              }
            }
          }
          this.refundBlock.is_show = !0;
          num_list[index].checked = !0;
          num_list[index].num_arr = info.data[index == 3 ? 'average_dateline_time_success' : num_list[index].plate_type];
          this.$nextTick(() => {
            this.$refs.refund_kanban.initData();
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取退款商品
    getRefundGoods() {
      this.$axios.post(this.$api.data.refundGoods, { store_id: this.store_id }).then(res => {    
        if (res.code == 0) {
          let list = res.result;
          for (let i in list) {
            list[i].rank = Number(i) + 1;
            list[i].refund_amount = Math.floor(list[i].refund_amount * 100) / 100;
            list[i].order_amount = Math.floor(list[i].order_amount * 100) / 100;
            list[i].num = Math.floor(list[i].num * 100) / 100;
            list[i].refund_rate = Math.floor(list[i].refund_rate * 100) / 100;
          }
          this.refundGoodsList = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取网店交易数据
    getStore_transaction() {
      this.$axios.post(this.$api.data.store_transaction).then(res => {
        if (res.code == 0) {
          let info = res.result;
          info.visit_order_rate = info.create_order_num_today > 0 && info.today_visit_num > 0 ? ((info.create_order_num_today / info.today_visit_num) * 100).toFixed(2) : '0.00';
          info.order_pay_rate = info.pay_order_num_today > 0 && info.create_order_num_today > 0 ? ((info.pay_order_num_today / info.create_order_num_today) * 100).toFixed(2) : '0.00';
          info.visit_pay_rate = info.pay_order_num_today > 0 && info.today_visit_num > 0 ? ((info.pay_order_num_today / info.today_visit_num) * 100).toFixed(2) : '0.00';
          this.storeRate = info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取门店
    getStore() {
      this.$axios.post(this.$api.store.storeList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          res.result.list.unshift({
            id: 0,
            store_name: '总店',
          });
          if (res.result.list.length) {
            res.result.list.unshift({
              id: -1,
              store_name: '全部',
            });
          }
          this.storeList = res.result.list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
  .header {
    background: #f8f9fb;
    display: flex;
    align-items: center;
    padding-left: 12px;
    height: 40px;
    span:nth-child(1) {
      width: 3px;
      height: 11px;
      background: #409eff;
      margin-right: 8px;
    }
    span:nth-child(2) {
      color: #333;
    }
  }
  .select_store {
    background: #fff;
    padding: 15px 20px;
    display: flex;
    .el-form-item {
      margin: 0;
      margin-right: 25px;
      &:nth-child(1) {
        .el-select {
          width: 100px;
          margin-right: 10px;
        }
      }
    }
  }
  .day-data {
    padding: 0 20px 20px 20px;
    margin-top: 7px;
    background: #fff;
    .transaction-rate {
      margin-top: 20px;
      .transaction-rate-con {
        margin-top: 20px;
        position: relative;
        width: 100%;
        .jiantou {
          position: absolute;
          left: 1227px;
          top: 0;
          width: 300px;
          height: 428px;
          color: #666;
          .el-image {
            position: absolute;
            width: 200px;
            left: 0;
            top: 0;
            height: 100%;
          }
          .visit-order {
            position: absolute;
            text-align: center;
            p:nth-child(1) {
              margin-bottom: 4px;
            }
          }
          .visit-order1 {
            z-index: 10;
            left: 53px;
            top: 128px;
          }
          .visit-order2 {
            z-index: 10;
            left: 70px;
            top: 290px;
          }
          .visit-order3 {
            z-index: 10;
            left: 200px;
            top: 200px;
          }
        }
        .item {
          width: 1200px;
          margin-bottom: 4px;
          height: 140px;
          position: relative;
          padding-left: 19px;
          .visit-num {
            display: flex;
            font-size: 12px;
            height: 100%;
            align-items: center;
            .visit-num-item {
              margin-right: 180px;
              &:last-child {
                margin: 0;
              }
              p {
                margin-bottom: 10px;
                &:last-child {
                  margin: 0;
                }
              }
              p:nth-child(2) {
                font-size: 20px;
              }
              p:nth-child(3),
              p:nth-child(3) {
                color: #666;
              }
            }
          }
          .type {
            .name {
              position: absolute;
              height: 50px;
              margin-top: 57px;
              width: 100%;
              text-align: center;
              color: #fff;
              .el-image {
                margin-bottom: 5px;
              }
            }
          }
          &:nth-child(1) {
            background: #f3f7fd;
            .type {
              position: absolute;
              right: 0;
              top: 0;
              border-bottom: 140px solid #5654f3;
              border-left: 14px solid transparent;
              border-right: 14px solid transparent;
              height: 0;
              width: 90px;
              transform: translateX(20px);
            }
          }
          &:nth-child(2) {
            background: #f3fdfd;
            .type {
              position: absolute;
              right: 0;
              top: 0;
              border-bottom: 140px solid #0bced9;
              border-left: 14px solid transparent;
              border-right: 14px solid transparent;
              height: 0;
              width: 117px;
              transform: translateX(34px);
            }
          }
          &:nth-child(3) {
            background: #fff7fa;
            .type {
              position: absolute;
              right: 0;
              top: 0;
              border-bottom: 140px solid #fd5e94;
              border-left: 14px solid transparent;
              border-right: 14px solid transparent;
              width: 150px;
              height: 140px;
              transform: translateX(51px);
            }
          }
        }
      }
    }
    .whole-kanban {
      margin-top: 20px;
    }
    .store-rank {
      margin-top: 20px;
      #store-rank {
        height: 400px;
        width: 100%;
      }
    }
    .refund-kanban {
      margin-top: 20px;
    }
    .refund-goods {
      margin-top: 40px;
      .el-table {
        margin-top: 27px;
      }
    }
  }
}
</style>
